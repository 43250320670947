import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { API_ENDPOINT, USER } from "../../../constants/constants";
import CustomCircularP from "../../../widgets/CustomCircularP";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
import {
  GROUP_ONE,
  getOnsiteHeader,
  toastError,
} from "../../../utils2024/utils";
class PrintableComponent extends React.Component {
  render() {
    return (
      <Box
        sx={{
          width: "9.8cm",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
      >
        {this.props.data.map((row, index) => {
          const vCard = [
            "BEGIN:VCARD",
            "VERSION:3.0",
            `NOTE: Barcode ID: ${row?._id}`,
            `N:${row?.lastName};${row?.firstName};;;`,
            `FN:${row?.title} ${row?.firstName} ${row?.lastName}`,
            `ORG:${row?.companyName.value}`,
            `TITLE:${row?.jobTitle}`,
            `EMAIL;TYPE=PREF,INTERNET:${row?.email}`,
            "END:VCARD",
          ].join("\n");
          return (
            <div key={index} style={{ pageBreakAfter: "always" }}>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    marginTop: "5.4cm",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  {row?.firstName.toUpperCase()} {row?.lastName.toUpperCase()}
                </div>
                <div
                  style={{
                    fontSize: "19px",
                    marginTop: "8px",
                    textAlign: "center",
                  }}
                >
                  {row?.companyName.value.toUpperCase()}
                </div>
              </div>
              <Box mt={2}>
                <QRCode value={vCard} size={130} />
              </Box>
            </div>
          );
        })}
      </Box>
    );
  }
}

class PrintableRowComponent extends React.Component {
  render() {
    const { row } = this.props;

    const vCard = row._id;

    // [
    //   "BEGIN:VCARD",
    //   "VERSION:3.0",
    //   `NOTE: Barcode ID: ${row._id}`,
    //   `N:${row.lastName};${row.firstName};;;`,
    //   `FN:${row.title} ${row.firstName} ${row.lastName}`,
    //   `ORG:${row.companyName.value}`,
    //   `TITLE:${row.jobTitle}`,
    //   `EMAIL;TYPE=PREF,INTERNET:${row.email}`,
    //   "END:VCARD",
    // ].join("\n");
    return (
      <div
        style={{
          textAlign: "center",
          width: "9.8cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{ marginTop: "5.6cm", fontWeight: "bold", fontSize: "21px" }}
          >
            {row.firstName.toUpperCase()} {row.lastName.toUpperCase()}
          </div>
          <div style={{ fontSize: "19px", marginTop: "12px" }}>
            {row.companyName.value.toUpperCase()}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <QRCode value={vCard} size={130} />
        </div>
      </div>
    );
  }
}
const ForwardPrintableComponent = React.forwardRef((props, ref) => (
  <PrintableComponent ref={ref} {...props} />
));
const BulkPrint = () => {
  useEffect(() => {
    document.title = "EXHIBITOR BADGES";
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [exhibitorId, setExhibitorId] = useState("");
  const [checking, setChecking] = useState(false);
  const [hallNumber, setHallNumber] = useState("");
  const [exhibitors, setExhibitors] = useState([]);
  const [teamMs, setTeamMs] = useState([]);
  const [originalTeamMs, setOriginalTeamMs] = useState([]);
  const [loadingExhibitors, setLoadingExhibitors] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchQ, setSearchQ] = useState("");
  const filteredTeamMs = searchQ
    ? teamMs.filter(
        (tm) =>
          tm.firstName.toLowerCase().includes(searchQ) ||
          tm.lastName.toLowerCase().includes(searchQ)
      )
    : teamMs;

  const printComponentRef = useRef();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = teamMs.map((n) => n._id); // Assuming each team member has a unique _id
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleHallChange = (e) => {
    const hall = e.target.value;
    setHallNumber(hall);
    loadExhibitors(hall);
    loadTmsHall(hall);
  };
  const handleExhibitorChange = (event, newValue) => {
    setExhibitorId(newValue ? newValue.username : "");
    if (newValue?.username) {
      const filteredTeamMs = originalTeamMs.filter(
        (sx) => sx.owner === newValue.username
      );
      setTeamMs(filteredTeamMs);
    } else {
      setTeamMs(originalTeamMs); // Reset to the original list if no exhibitor is selected
    }
  };
  async function loadExhibitors(hallN) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}${USER}/get-onsite-exhibitors?hallId=${hallN}`,
        getOnsiteHeader(GROUP_ONE)
      );
      if (response.status === 200) {
        setExhibitors(response.data);
      } else if (response.status === 204) {
        // Handle successful response with no content
        toast.info("No Exhibitor Found with that ID.");
      }
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }
  const handleSearch = (e) => {
    const vl = e.target.value;
    setSearchQ(vl);
  };
  async function loadTmsHall(hallName) {
    setLoadingExhibitors(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}${USER}/get-team-members-by-hall?hallName=${hallName}`,
        getOnsiteHeader(GROUP_ONE)
      );
      if (response.status === 200) {
        setTeamMs(response.data);
        setOriginalTeamMs(response.data);
      } else if (response.status === 204) {
        // Handle successful response with no content
        toast.info("No Team members found!");
        setTeamMs([]);
        setOriginalTeamMs([]);
      }
    } catch (error) {
      toastError(error);
    } finally {
      setLoadingExhibitors(false);
    }
  }
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const printRow = (row) => {
    const printableContent = ReactDOMServer.renderToString(
      <PrintableRowComponent row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
    axios.post(
      `${API_ENDPOINT}${USER}/save-printed-team-member`,
      { id: row._id },
      getOnsiteHeader(GROUP_ONE)
    );
  };
  const handleRowSelect = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(item._id)) {
        // If the item is already selected, remove it from the selection
        return prevSelected.filter((id) => id !== item._id);
      } else {
        // If the item is not selected, add it to the selection
        return [...prevSelected, item._id];
      }
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <div style={{ display: "none" }}>
        <ForwardPrintableComponent
          ref={printComponentRef}
          data={selected.map((id) => teamMs.find((row) => row._id === id))}
        />
      </div>
      <CustomCircularP show={loadingExhibitors} />
      <Box
        mt={1}
        width={"100%"}
        maxWidth={700}
        pl={1}
        pr={1}
        ml={"auto"}
        mr={"auto"}
      >
        {/* <Typography
          textAlign={"center"}
          color={"rgb(151, 42, 76)"}
          fontWeight={"bold"}
          fontSize={"28px"}
        >
          EXHIBITOR BADGES
        </Typography> */}
        <Box mt={2} width={"100%"} display={"flex"} gap={2}>
          <FormControl size="small" fullWidth>
            <InputLabel>Hall Number</InputLabel>
            <Select
              value={hallNumber}
              label="Select HAll Number"
              onChange={handleHallChange}
            >
              {/* <MenuItem value={"Hall 1"}>Hall 1</MenuItem>
              <MenuItem value={"Hall 2"}>Hall 2</MenuItem> */}
            </Select>
          </FormControl>
          <Autocomplete
            size="small"
            fullWidth
            options={exhibitors}
            getOptionLabel={(option) => option.companyName.value}
            onChange={handleExhibitorChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Exhibitor" />
            )}
          />
        </Box>
        <Paper
          sx={{
            mt: 2,
            padding: "2px 4px 2px 10px",
            backgroundColor: "#f4f4f4",
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={"Search Members by Name"}
            value={searchQ}
            onChange={handleSearch}
            inputProps={{
              "aria-label": "Search Members by Name",
            }}
          />
          <IconButton type="submit" sx={{ p: "8px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      <Box p={3} maxWidth={1280} ml={"auto"} mr={"auto"} width={"100%"}>
        <Box display={"flex"}>
          <Box flex={1}>
            <Typography fontWeight={"bold"}>
              Selected: {selected.length}
            </Typography>
          </Box>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" startIcon={<PrintIcon />}>
                Print All
              </Button>
            )}
            content={() => printComponentRef.current}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        indeterminate={
                          selected.length > 0 && selected.length < teamMs.length
                        }
                        checked={
                          teamMs.length > 0 && selected.length === teamMs.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    }
                    label="Select All"
                  />
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Profile Image</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Company Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Phone</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                {/* <TableCell sx={{ fontWeight: "bold" }}>Role</TableCell> */}
                <TableCell sx={{ fontWeight: "bold" }}>Job Title</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Print</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTeamMs.map((row, i) => {
                const isItemSelected = isSelected(row._id);
                return (
                  <TableRow key={i}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        onChange={() => handleRowSelect(row)}
                        checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {row.profile_image ? (
                        <Avatar
                          alt={`${row.firstName} ${row.lastName}`}
                          src={row.profile_image}
                        />
                      ) : (
                        <PersonIcon />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.firstName} {row.lastName}
                    </TableCell>
                    <TableCell>{row.companyName.value}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    {/* <TableCell>{row.role}</TableCell> */}
                    <TableCell>{row.jobTitle}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          width: "96px",
                          bgcolor: row.print_count > 0 ? "red" : "primary",
                        }}
                        startIcon={<PrintIcon />}
                        size="small"
                        onClick={() => {
                          printRow(row);
                        }}
                      >
                        Print {row.print_count}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default BulkPrint;
