import React, { useEffect, useState } from "react";
import { Box, Button, Container, Stack, Tab, Tabs } from "@mui/material";
import BackBtn from "../../admin/widgets/BackBtn";
import MeetingsList from "./MeetingsList";
import { getUser } from "../../../../utility/utility";
import { EXHIBITOR } from "../../../../constants/constants";
import TeamMeetingsList from "./TeamMeetingsList";

const statusOptions = [
  { label: "All", value: "", color: "primary" }, // Default color
  { label: "Accepted", value: "accepted", color: "success" },
  { label: "Cancelled", value: "cancelled", color: "error" },
];

const AllMeetings = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [status, setStatus] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (tabIndex === 0) {
      setStatus("requested");
    }
    if (tabIndex === 1) {
      setStatus("");
    }
  }, [tabIndex]);

  const handleStatusChange = (statusValue) => {
    setStatus(statusValue);
  };

  const meetingType = tabIndex === 0 ? "received" : "sent";

  return (
    <Box>
      <BackBtn />
      <Container maxWidth="lg">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Meeting Requests"
            sx={{
              textTransform: "none",
              fontSize: { xs: "14px", sm: "16px" },
              fontWeight: "bold",
              "&.Mui-selected": { color: "#2196f3" },
              "&:hover": { color: "#1565c0" },
            }}
          />
          <Tab
            label="My Meetings"
            sx={{
              textTransform: "none",
              fontSize: { xs: "14px", sm: "16px" },
              fontWeight: "bold",
              "&.Mui-selected": { color: "#2196f3" },
              "&:hover": { color: "#1565c0" },
            }}
          />
          {getUser()?.role === EXHIBITOR && (
            <Tab
              label="Team Member Meetings"
              sx={{
                textTransform: "none",
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: "bold",
                "&.Mui-selected": { color: "#2196f3" },
                "&:hover": { color: "#1565c0" },
              }}
            />
          )}
        </Tabs>

        {status !== "requested" && (
          <Stack
            maxWidth={800}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            sx={{
              mt: 2,
              alignItems: { xs: "stretch", sm: "center" },
            }}
          >
            {statusOptions.map((option) => (
              <Button
                key={option.value}
                variant={status === option.value ? "contained" : "outlined"}
                color={option.color}
                onClick={() => handleStatusChange(option.value)}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                {option.label}
              </Button>
            ))}
          </Stack>
        )}

        <Box sx={{ mt: 2, px: { xs: 2, sm: 0 } }}>
          {tabIndex < 2 ? (
            <MeetingsList type={""} status={status} />
          ) : (
            <TeamMeetingsList status={status} />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default AllMeetings;
