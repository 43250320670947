import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import BackBtn from "../admin/widgets/BackBtn";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import MuPb from "../../../widgets/MuPb";
import CommonItemCard from "../../../widgets/CommonItemCard";

const MutualFavorites = () => {
  const { isLoading, isError, error, data } = useCommonGetInit(
    `user/get-mutual-likes`
  );

  if (isLoading) {
    return <MuPb />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>; // Display error message if there is an error
  }

  return (
    <Box mt={1} ml={2}>
      <BackBtn />
      <Typography variant="h6" gutterBottom>
        Your mutual connections
      </Typography>
      <Box ml={1} mt={4}>
        <Grid container spacing={2}>
          {data.map((user, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <CommonItemCard item={user} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default MutualFavorites;
