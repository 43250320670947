// ChatApp.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  TextField,
  IconButton,
  Button,
  Divider,
  ListItemButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BackBtn from "../dashboard/admin/widgets/BackBtn";
import { getHeader, getUserName, useQuery } from "../../utility/utility";
import useCommonGetInit from "../events/hooks/useCommonGetInit";
import { API_ENDPOINT, USER, VISITOR } from "../../constants/constants";
import MuPb from "../../widgets/MuPb";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import axios from "axios";
import { format } from "date-fns";
import { getCommonUrl, toastError } from "../../utils2024/utils";
import MyChatList from "../dashboard/common/MyChatList";

function ChatApp() {
  const query = useQuery();
  const id = query.get("tid");
  const [sendingMsg, setSendingMsg] = useState(false);
  const [localMessages, setLocalMessages] = useState([]);
  const {
    data: chatData,
    isLoading: chatLoading,
    isError: isChatError,
    error: chatError,
  } = useCommonGetInit(`${USER}/get-my-chat?id=${id}&`);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (chatData?.messages) {
      setLocalMessages(chatData.messages);
    }
  }, [chatData]);

  const messageBubbleStyle = (sent) => ({
    maxWidth: "70%",
    marginLeft: sent ? "auto" : 0,
    marginRight: sent ? 0 : "auto",
    backgroundColor: sent ? "#e0f7fa" : "#eceff1",
    borderRadius: sent ? "20px 20px 0 20px" : "20px 20px 20px 0",
    padding: "10px 16px",
    marginBottom: "8px",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)",
    border: sent ? "1px solid #b2ebf2" : "1px solid #cfd8dc",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      ...(sent
        ? {
            left: "100%",
            borderLeft: "10px solid #e0f7fa",
          }
        : {
            right: "100%",
            borderRight: "10px solid #eceff1",
          }),
    },
  });

  const onSendMessage = async () => {
    if (message === "") {
      toast.error("Please type the message to send");
      return;
    }
    try {
      const messageToSend = {
        recipient_id: chatData?.recipient?.username,
        message: message,
      };
      setSendingMsg(true);
      const response = await axios.post(
        `${API_ENDPOINT}user/send-message-to`,
        messageToSend,
        getHeader()
      );

      setLocalMessages((prevMessages) => [
        ...prevMessages,
        {
          message: message,
          sender_id: chatData?.sender?.username,
          createdOn: new Date().toISOString(),
        },
      ]);

      setMessage("");
      toast.success(response.data.message);
    } catch (error) {
      toast.error("Failed to send message");
    } finally {
      setSendingMsg(false);
    }
  };

  if (chatLoading) {
    return <MuPb />;
  }

  return (
    <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, height: "calc(100vh - 82px)", overflowY: "auto" }}
          >
            <Box mb={1}>
              <BackBtn />
            </Box>
            <TextField
              fullWidth
              size="small"
              placeholder="Search Chats"
              InputProps={{
                startAdornment: (
                  <IconButton size="small" position="start">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <Divider sx={{ my: 2 }} />
            <MyChatList internal={true} />
            {/*<List sx={{ overflow: "auto" }}>
            
              {chatData?.contacts?.map((contact, index) => (
                <ListItemButton key={index}>
                  <ListItemAvatar>
                    <Avatar src={contact.profile_image} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${contact.firstName} ${contact.lastName}`}
                    secondary={contact.lastMessage}
                  />
                </ListItemButton>
              ))}
            </List>*/}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "calc(100vh - 82px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box display={"flex"}>
                <Avatar
                  alt="Profile Picture"
                  src={chatData?.recipient?.profile_image}
                  sx={{ marginRight: 2 }}
                />
                <Typography variant="h6">
                  {chatData?.recipient && getUserName(chatData?.recipient)}
                </Typography>
              </Box>
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Box>
            <Divider />
            <List sx={{ overflow: "auto", flexGrow: 1, p: 3 }}>
              {localMessages.map((message, index) => (
                <ListItem key={index}>
                  <Box
                    sx={messageBubbleStyle(
                      message.sender_id === chatData?.sender?.username
                    )}
                  >
                    <ListItemText primary={message.message} />
                    <Typography variant="caption">
                      {format(
                        new Date(message.createdOn),
                        "dd/MM/yyyy hh:mm a"
                      )}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Divider />
            <Box
              component="form"
              sx={{ display: "flex", alignItems: "center", p: 2 }}
            >
              <TextField
                fullWidth
                size="small"
                disabled={!id}
                multiline
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                maxRows={4}
                placeholder="Enter your message"
                variant="outlined"
                sx={{ mr: 1 }}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                loading={sendingMsg}
                disabled={message === ""}
                endIcon={<SendIcon />}
                onClick={onSendMessage}
              >
                Send
              </LoadingButton>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ChatApp;
