import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  Chip,
} from "@mui/material";
import InteractiveAction2 from "../../../widgets/InteractiveAction2";
import StallViewer from "../../dashboard/common/StallViewer";

const UserDetailCard = ({ user }) => {
  const {
    companyName,
    contactSalutation,
    contactFirstName,
    contactLastName,
    contactDesignation,
    companyEmail,
    contactEmail,
    address1,
    address2,
    address3,
  } = user;

  return (
    <Card sx={{ maxWidth: 360, padding: 2, margin: "auto" }}>
      <StallViewer id={user.username} />
      <CardContent>
        <Box display="flex" justifyContent="center" mb={2}>
          <Avatar
            alt={companyName.value}
            sx={{
              width: 72,
              height: 72,
              bgcolor: "primary.main",
              fontSize: 28,
            }}
          >
            {companyName.value.charAt(0)}
          </Avatar>
        </Box>

        <Box textAlign="center" mb={2}>
          <Typography variant="h6" fontWeight="bold">
            {companyName.value}
          </Typography>
        </Box>
        <Box textAlign="center" mb={2}>
          {/* <Typography variant="subtitle1" fontWeight="medium">
            Contact Person
          </Typography> */}
          <Typography variant="body1">
            {`${contactSalutation.value} ${contactFirstName.value} ${contactLastName.value}`}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {contactDesignation.value}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} mt={2} mb={2}>
          <InteractiveAction2
            item={user}
            type={"exhibitor"}
            showDetail={true}
          />
        </Box>

        <Box mb={1}>
          <Typography variant="subtitle2" color="textSecondary">
            Company Email
          </Typography>
          <Typography variant="body1">{companyEmail.value}</Typography>
        </Box>

        <Box mb={1}>
          <Typography variant="subtitle2" color="textSecondary">
            Contact Email
          </Typography>
          <Typography variant="body1">{contactEmail.value}</Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="subtitle2" color="textSecondary">
            Address
          </Typography>
          <Typography variant="body2">
            {[user.address1.value, user.address2.value, user.address3.value]
              .filter(Boolean)
              .join(", ")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          {(user.productCategories.value || []).map((category, index) => {
            // Extract the part after the dash
            const subSubCatName = category.subSubCat.split(" - ")[1];
            return (
              <Chip
                key={index}
                label={subSubCatName}
                sx={{
                  width: "100%",
                  ".MuiChip-label": {
                    whiteSpace: "normal",
                  },
                }}
              />
            );
          })}
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserDetailCard;
