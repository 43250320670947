import React, { useContext, useEffect, useState } from "react";
import "../dashboard.css";
import Footer from "../../footer/footer";

import {
  EXHIBITOR_MENU_LIST,
  deleteUser,
  getHeader,
} from "../../../utility/utility";
import Dashboard from "./Dashboard";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Settings from "./Settings";
import Services from "./oes/Services";
import Onsite from "./Onsite";
import Reports from "./Reports";
import ProfileDetails from "./profile/ProfileDetails";
import SideMenuList from "./profile/SideMenuList";
import MuPb from "../../../widgets/MuPb";
import { Alert, Box, useMediaQuery, useTheme } from "@mui/material";
import ExhibitionCatalogue from "./oes/ExhibitionCatalogue";
import MySchedule from "./MySchedule";
import GeneralView from "../../floorplan/view/GeneralView";
import AllMeetings from "./meeting/AllMeetings";
import ProfileViewByID from "./profile/ProfileViewByID";
import ChatApp from "../../chatApp/ChatApp";
import MyFavorites from "../favorites/MyFavorites";
import ScheduleMeeting from "./meeting/ScheduleMeeting";
import ProductContainer from "./ProductContainer";
import ProfileViewers from "../favorites/ProfileViewers";
import MyStallViewers from "../favorites/MyStallViewers";
import MyChatList from "../common/MyChatList";
import NotificationsList from "./NotificationsList";
import { UserContext } from "../../context/UserContext";
import OesForms from "./OesForms";
import SupportRequest from "../../../pages/SupportRequest";
import AddTeamMembers from "./AddTeamMembers";
import TeamMembers from "./TeamMembers";
import ExhibitorHeader from "../header/ExhibitorHeader";
import Exhibitors from "./explore/Exhibitors";
import Visitors from "./explore/Visitors";
import VisitorProfileDetail from "../visitor/VisitorProfileDetail";
import Products from "./explore/Products";
import { io } from "socket.io-client";
import Matchmaking from "./explore/Matchmaking";
import axios from "axios";
import { API_ENDPOINT } from "../../../constants/constants";
import ExhibitorProfileView from "./profile/ExhibitorProfileView";
import ProfileDetailCommon from "../common/ProfileDetailCommon";
import MeetingDetail from "./meeting/MeetingDetail";
import MeetingItem from "./meeting/Meeting";
import MeetingReschedule from "./meeting/MeetingReschedule";
import MutualFavorites from "../favorites/MutualFavorites";
const ExhibitorDashboard = () => {
  const navigate = useNavigate();
  const { page } = useParams();

  const loc = document.location.href.replace(/\/+$/, "");
  const keysUrl = loc.split("/");
  const isOes = keysUrl[4] && keysUrl[4].includes("oes-forms");
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { user, loading } = useContext(UserContext);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(API_ENDPOINT, {
      transports: ["websocket"],
    });

    // Log successful connection
    newSocket.on("connect", () => {
      console.log(`Connected to server with socket ID: ${newSocket.id}`);
    });

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  useEffect(() => {
    if (socket !== null) {
      console.log(`Socket connected: ${socket}`);
    }
  }, [socket]);

  const onLogout = () => {
    deleteUser();
    navigate("/login");
  };
  if (loading) {
    return <MuPb />;
  }
  if (!user) {
    return <Alert severity="error">No user data found!</Alert>;
  }
  const showMenu = isLargeScreen && !isOes;

  return (
    <>
      <ExhibitorHeader />

      {!isOes && (
        <Box
          position={"fixed"}
          top={"86px"}
          width={200}
          display={isLargeScreen ? undefined : "none"}
        >
          <SideMenuList
            onLogout={onLogout}
            menuList={EXHIBITOR_MENU_LIST}
            page={page}
          />
        </Box>
      )}
      <Box
        component="main"
        display="flex"
        id="main-content-box"
        flexDirection="column"
        width="100%"
        sx={{
          marginTop: "86px", // Space below the header to start content
          height: "calc(100vh - 86px)", // Adjust to fill remaining height
          overflowY: "auto", // Enable scrolling inside the main content area
        }}
      >
        <Box
          pr={2}
          pl={isLargeScreen ? 4 : 2}
          pt={1}
          pb={4}
          flexGrow={1}
          ml={showMenu ? "200px" : undefined}
        >
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="profile" element={<ProfileDetails />} />
            <Route path="floor-plan" element={<GeneralView />} />
            <Route path="add-team-member" element={<AddTeamMembers />} />
            <Route path="edit-team-member/:id" element={<AddTeamMembers />} />
            <Route path="team-members" element={<TeamMembers />} />
            <Route path="explore/exhibitors-list" element={<Exhibitors />} />
            <Route path="explore/visitors" element={<Visitors />} />
            <Route path="explore/products" element={<Products />} />
            <Route path="explore/matchmaking" element={<Matchmaking />} />
            <Route path="visitor-view/:id" element={<VisitorProfileDetail />} />
            <Route
              path="exhibitor-view/:id"
              element={<ExhibitorProfileView />}
            />
            <Route
              path="exhibition-catalogue"
              element={<ExhibitionCatalogue />}
            />
            <Route path="meetings" element={<AllMeetings />} />
            <Route path="meeting/:id" element={<MeetingItem />} />
            <Route path="reschedule/:id" element={<MeetingReschedule />} />
            <Route path="exhibitor-services" element={<div />} />
            <Route path="services" element={<Services />} />
            <Route path="oes-forms" element={<OesForms />} />
            <Route path="my-schedule" element={<MySchedule />} />
            <Route path="support-request" element={<SupportRequest />} />
            <Route path="favorites" element={<MyFavorites />} />
            <Route path="settings" element={<Settings />} />
            <Route path="onsite" element={<Onsite />} />
            <Route path="reports" element={<Reports />} />
            <Route path="product-view/:id" element={<ProductContainer />} />
            <Route path="profile-viewers" element={<ProfileViewers />} />
            <Route path="stall-viewers" element={<MyStallViewers />} />
            <Route path="chat" element={<ChatApp />} />
            <Route path="profile-view/:id" element={<ProfileDetailCommon />} />
            <Route path="meeting-scheduler" element={<ScheduleMeeting />} />
            <Route path="my-chats" element={<MyChatList />} />
            <Route path="mutual-connections" element={<MutualFavorites />} />
            <Route path="my-notifications" element={<NotificationsList />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default ExhibitorDashboard;
