import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Paper,
  Divider,
  Box,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import BackBtn from "../admin/widgets/BackBtn";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { Link } from "react-router-dom";
import { getUser } from "../../../utility/utility";

const NotificationsList = () => {
  const {
    data: notifications,
    isLoading,
    isError,
  } = useCommonGetInit(`user/get-my-notifications`);

  useCommonGetInit(`user/seen-my-notifications`);

  return (
    <Box>
      <BackBtn />
      <Paper
        sx={{ maxWidth: 700, ml: "auto", mr: "auto", mt: 2, padding: "20px" }}
      >
        <Typography variant="h6" component="div" gutterBottom>
          Notifications
        </Typography>
        <List>
          {notifications?.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <ListItem
                component={"a"}
                href={
                  notification.type === "chat"
                    ? `/${getUser()?.role}/my-chats`
                    : `/${getUser()?.role}/meetings`
                } // Replace with the actual link property in your notification object
                underline="none"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    backgroundColor: "#f5f5f5", // Light hover effect
                    cursor: "pointer",
                  },
                }}
              >
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={notification.message}
                  secondary={new Date(notification.createdOn).toLocaleString()}
                />
              </ListItem>
              {index < notifications.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default NotificationsList;
