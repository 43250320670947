import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

export function StateSelector({
  countryCode,
  selectedState,
  onChange,
  required,
}) {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!countryCode) {
      setStates([]);
      return;
    }

    const fetchStates = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://apis.expoplanner.in/visitor/get-states-of-country/${countryCode}`
        );
        const data = await response.json();
        const stateData = data.map((state) => ({
          name: state.name,
          code: state.isoCode,
        }));
        setStates(stateData);
      } catch (error) {
        console.error("Error fetching states:", error);
        setStates([]);
      } finally {
        setLoading(false);
      }
    };

    fetchStates();
  }, [countryCode]);

  const handleChange = (event, value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Autocomplete
      size="small"
      options={states}
      value={selectedState || null}
      onChange={handleChange}
      getOptionLabel={(option) => (option ? option.name : "")}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      loading={loading}
      disabled={!countryCode}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label="State"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default StateSelector;
