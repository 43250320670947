import React, { useContext } from "react";
import "../dashboard.css";
import Footer from "../../footer/footer";
import Dashboard from "./Dashboard";
import { deleteUser, getUser } from "../../../utility/utility";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import SideMenuList from "../exhibitor/profile/SideMenuList";
import MuPb from "../../../widgets/MuPb";
import { Alert, Box, useMediaQuery, useTheme } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import PrintBadge from "./PrintBadge";
import VisitorProfile from "./VisitorProfile";
import VisitorHeader from "../header/VisitorHeader";
import VisitorProfileDetail from "./VisitorProfileDetail";
import Exhibitors from "../exhibitor/explore/Exhibitors";
import ProfileViewByID from "../exhibitor/profile/ProfileViewByID";
import Products from "../exhibitor/explore/Products";
import ProductContainer from "../exhibitor/ProductContainer";
import ExhibitorProfileView from "../exhibitor/profile/ExhibitorProfileView";
import ExhibitorMatching from "./explore/Matchmaking";
import GeneralView from "../../floorplan/view/GeneralView";
import MyFavorites from "../favorites/MyFavorites";
import ScheduleMeeting from "../exhibitor/meeting/ScheduleMeeting";
import ProfileViewers from "../favorites/ProfileViewers";
import ProfileDetailCommon from "../common/ProfileDetailCommon";
import AllMeetings from "../exhibitor/meeting/AllMeetings";
import MeetingItem from "../exhibitor/meeting/Meeting";
import MeetingReschedule from "../exhibitor/meeting/MeetingReschedule";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FloodIcon from "@mui/icons-material/Flood";
import BadgeIcon from "@mui/icons-material/Badge";
import ExploreIcon from "@mui/icons-material/Explore";
import Visitors from "../exhibitor/explore/Visitors";
import MyChatList from "../common/MyChatList";
import ChatApp from "../../chatApp/ChatApp";
import NotificationsList from "../exhibitor/NotificationsList";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MutualFavorites from "../favorites/MutualFavorites";
const MyMenuItems = [
  {
    to: "/visitor",
    text: "Dashboard",
    icon: DashboardIcon,
  },
  {
    to: "",
    text: "Explore",
    icon: ExploreIcon,
    sublinks: [
      {
        link: "/visitor/explore/exhibitors",
        label: "Exhibitors",
      },
      {
        link: "/visitor/explore/products",
        label: "Products",
      },
      {
        link: "/visitor/explore/exhibitor-matching",
        label: "B2B AI Matchmaking",
      },
    ],
  },
  {
    to: "/visitor/floor-plan",
    text: "Floor Plan",
    icon: FloodIcon,
  },
  {
    to: "/visitor/meetings",
    text: "Meetings Scheduled",
    icon: MeetingRoomIcon,
  },
  {
    to: "/visitor/favorites",
    text: "My Favorites",
    icon: FavoriteIcon,
  },
  {
    to: "print-badge",
    text: "Print Badge",
    icon: BadgeIcon,
  },
];
const VisitorDashboard = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { user, loading, reloadUser } = useContext(UserContext);
  const onLogout = () => {
    deleteUser();
    navigate("/login");
  };
  if (loading) {
    return <MuPb />;
  }
  if (!user) {
    return <Alert severity="error">No user data available!</Alert>;
  }
  const conditionalMenuList = MyMenuItems.map((item) => {
    if (item.text === "Explore" && getUser()?.role === "team") {
      return {
        ...item,
        sublinks: [
          ...(item.sublinks || []),
          {
            link: "/visitor/explore/visitors",
            label: "Visitors",
          },
        ],
      };
    }
    if (item.to === "print-badge" && getUser()?.role === "team") {
      return null;
    }
    return item;
  }).filter(Boolean);

  return (
    <>
      <VisitorHeader />
      <Box
        position={"fixed"}
        top={"86px"}
        width={200}
        display={isLargeScreen ? undefined : "none"}
      >
        <SideMenuList
          onLogout={onLogout}
          menuList={conditionalMenuList}
          page={page}
        />
      </Box>
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{
          marginTop: "86px",
          height: "calc(100vh - 86px)",
          overflowY: "auto",
        }}
      >
        <Box
          pr={2}
          pl={isLargeScreen ? 4 : 2}
          pt={1}
          pb={4}
          flexGrow={1}
          ml={isLargeScreen ? "200px" : undefined}
        >
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route
              path="profile"
              element={<VisitorProfile isEditMode={true} />}
            />
            <Route path="favorites" element={<MyFavorites />} />
            <Route path="meetings" element={<AllMeetings />} />
            <Route path="meeting/:id" element={<MeetingItem />} />
            <Route path="reschedule/:id" element={<MeetingReschedule />} />
            <Route path="floor-plan" element={<GeneralView />} />
            <Route path="explore/exhibitors" element={<Exhibitors />} />
            <Route path="explore/visitors" element={<Visitors />} />
            <Route path="view-profile" element={<ProfileViewByID />} />
            <Route path="explore/products" element={<Products />} />
            <Route path="product-view/:id" element={<ProductContainer />} />
            <Route
              path="explore/exhibitor-matching"
              element={<ExhibitorMatching />}
            />
            <Route path="visitor-view/:id" element={<VisitorProfileDetail />} />
            <Route
              path="exhibitor-view/:id"
              element={<ExhibitorProfileView />}
            />
            <Route path="my-chats" element={<MyChatList />} />
            <Route path="chat" element={<ChatApp />} />
            <Route path="my-notifications" element={<NotificationsList />} />
            <Route path="profile-view/:id" element={<ProfileDetailCommon />} />
            <Route path="profile-viewers" element={<ProfileViewers />} />
            <Route path="meeting-scheduler" element={<ScheduleMeeting />} />
            <Route path="visitor-view/:id" element={<VisitorProfileDetail />} />
            <Route path="print-badge" element={<PrintBadge user={user} />} />
            <Route path="mutual-connections" element={<MutualFavorites />} />
          </Routes>
        </Box>
      </Box>

      <Footer />
    </>
  );
};

export default VisitorDashboard;
