import React from "react";
import "./regHeader.css";
import { EMAIL_HEADER } from "../../constants/constants";
const RegHeader = () => {
  return (
    <a href="https://www.imtex.in/" target="_blank">
      <div
        className="newhcontainer"
        style={{
          backgroundImage: `url(${"https://imtex2024-imtma.expoplanner.in/static/media/header_bg.780c40d922984ce51348.png"})`,
        }}
      >
        <img
          style={{ height: "100%" }}
          className="newhheader-image"
          src={EMAIL_HEADER}
          alt="App Header"
        />
      </div>
    </a>
  );
};

export default RegHeader;
