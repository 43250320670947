import React, { useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CitySelector from "../widgets/CitySelector";
import StateSelector from "../widgets/StateSelector";
import CountrySelect from "../widgets/CountrySelector";
import { MuiTelInput } from "mui-tel-input";
import {
  API_ENDPOINT,
  EMAIL_FOOTER,
  EMAIL_HEADER,
} from "../constants/constants";
import { LoadingButton } from "@mui/lab";
import { getDbHeader, toastError } from "../utils2024/utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const OpenStudentRegistration = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    companyName: "",
    education: "",
    department: "",
    address: "",
    country: null,
    state: null,
    city: null,
    pinZip: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}user/student-register`,
        formData,
        getDbHeader()
      );
      toast.success(data.message);

      setTimeout(() => {
        let reg_id = data.reg_id;
        navigate(`/reg-qr/${reg_id}`);
      }, 200);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "900px",
        margin: "auto",
        padding: "16px",
        border: "1px solid #ddd",
        borderRadius: "8px",
      }}
    >
      {/* Header Image */}
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <img src={EMAIL_HEADER} alt="Header" style={{ width: "100%" }} />
      </Box>

      <form onSubmit={handleSubmit}>
        <Typography variant="h5" align="center" gutterBottom>
          Student Registration
        </Typography>

        <Grid container spacing={2} mt={2}>
          {/* Title */}
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="title-label">Title</InputLabel>
              <Select
                value={formData.title}
                name="title"
                label="Title"
                onChange={handleInputChange("title")}
                required
              >
                {["Mr.", "Ms.", "Dr.", "Prof."].map((position, i) => (
                  <MenuItem key={i} value={position}>
                    {position}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* First Name */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              value={formData.firstName}
              onChange={handleInputChange("firstName")}
              size="small"
              required
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              value={formData.lastName}
              onChange={handleInputChange("lastName")}
              size="small"
              required
            />
          </Grid>

          {/* Email */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              value={formData.email}
              onChange={handleInputChange("email")}
              size="small"
              required
            />
          </Grid>

          {/* Mobile Number */}
          <Grid item xs={6}>
            <MuiTelInput
              fullWidth
              size="small"
              label="Mobile Number"
              defaultCountry="IN"
              forceCallingCode
              value={formData.mobileNo}
              onChange={(value) =>
                setFormData({ ...formData, mobileNo: value })
              }
              required
            />
          </Grid>

          {/* Institute Name */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Institute Name"
              value={formData.companyName}
              onChange={handleInputChange("companyName")}
              size="small"
              required
            />
          </Grid>

          {/* Department */}
          <Grid item xs={6}>
            <FormControl required fullWidth size="small">
              <InputLabel id="department-label">Department</InputLabel>
              <Select
                value={formData.department}
                name="department"
                label="Department"
                onChange={handleInputChange("department")}
                required
              >
                {[
                  "ITI",
                  "Mechanical",
                  "E&C",
                  "EEE",
                  "Industrial Production",
                  "Aeronautical",
                  "Automobile",
                  "Others",
                ].map((dept, i) => (
                  <MenuItem key={i} value={dept}>
                    {dept}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Education */}
          <Grid item xs={6}>
            <FormControl required fullWidth size="small">
              <InputLabel id="education-label">Education</InputLabel>
              <Select
                value={formData.education}
                name="education"
                label="Education"
                onChange={handleInputChange("education")}
                required
              >
                {["Diploma", "BE/BTech", "ME/MTech", "Others"].map(
                  (education, i) => (
                    <MenuItem key={i} value={education}>
                      {education}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>

          {/* Address */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              value={formData.address}
              onChange={handleInputChange("address")}
              size="small"
            />
          </Grid>

          {/* Country Selector */}
          <Grid item xs={6}>
            <CountrySelect
              selectedCountry={formData.country}
              onChange={(newCountry) =>
                setFormData({ ...formData, country: newCountry })
              }
              required
            />
          </Grid>

          {/* State Selector */}
          <Grid item xs={6}>
            <StateSelector
              countryCode={formData.country?.code}
              selectedState={formData.state}
              onChange={(newState) =>
                setFormData({ ...formData, state: newState })
              }
              required
            />
          </Grid>

          {/* City Selector */}
          <Grid item xs={6}>
            <CitySelector
              countryCode={formData.country?.code}
              stateCode={formData.state?.code}
              selectedCity={formData.city}
              onChange={(newCity) =>
                setFormData({ ...formData, city: newCity })
              }
              required
            />
          </Grid>

          {/* Pin/Zip Code */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Pin/Zip Code"
              value={formData.pinZip}
              onChange={handleInputChange("pinZip")}
              size="small"
            />
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </LoadingButton>
        </Box>
      </form>

      {/* Footer Image */}
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <img src={EMAIL_FOOTER} alt="Footer" style={{ width: "100%" }} />
      </Box>
    </Box>
  );
};

export default OpenStudentRegistration;
