import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { UserContext } from "../../context/UserContext";
import { Info as InfoIcon } from "@mui/icons-material";
import { round } from "lodash";

const ExhibitorBadgeInfo = ({ properties }) => {
  const { basenum, basesqm, extranum, extrasqm } = properties;

  // Convert string properties to integers
  const intBasenum = parseInt(basenum, 10);
  const intBasesqm = parseInt(basesqm, 10);
  const intExtranum = parseInt(extranum, 10);
  const intExtrasqm = parseInt(extrasqm, 10);

  const { user } = useContext(UserContext);
  const [totalFreeBadges, setTotalFreeBadges] = useState(0);

  const {
    data: membersData,
    isLoading: istmLoading,
    isError: istmError,
  } = useCommonGetInit(`user/get-team-members`);

  useEffect(() => {
    if (user) {
      // Total area of the exhibitor's stand
      const mySize = parseFloat(user.standSize.value || 0);

      const totalBadges =
        intBasenum +
        Math.ceil((mySize - intBasesqm) * (intExtranum / intExtrasqm));
      setTotalFreeBadges(totalBadges);
    }
  }, [user, intBasenum, intBasesqm, intExtranum, intExtrasqm]);

  const paidBadges = Math.max(
    0,
    (membersData?.totalItems || 0) - totalFreeBadges
  );

  return (
    <Card variant="outlined" sx={{ backgroundColor: "#f5f5f5", mb: 2 }}>
      <CardContent>
        {/* <Stack direction="row" alignItems="center" spacing={1} mb={2}>
          <InfoIcon color="primary" />
          <Typography variant="h6">Badge Entitlement Summary</Typography>
        </Stack>
        <Divider /> */}
        <TableContainer component={Box}>
          <Table size="small" aria-label="badge entitlement table">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    Total Free Badges:
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1" color="primary">
                    {totalFreeBadges}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    Paid Badges Required:
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1" color="secondary">
                    {paidBadges}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    Total Members:
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    {membersData?.totalItems || 0}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ExhibitorBadgeInfo;
