import React from "react";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import {
  Box,
  Typography,
  Stack,
  Card,
  CardActionArea,
  AvatarGroup,
  Avatar,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getUser } from "../utility/utility";

const MeetingCard = ({ meetingItem }) => {
  const members = [
    ...(meetingItem.selectedMembers || []),
    ...(meetingItem.selectedOtherMembers || []),
  ];
  const status = meetingItem.status;
  const statusColor =
    {
      requested: "info",
      accepted: "success",
      cancelled: "error",
    }[status] || "default";

  return (
    <Card
      component={Link}
      to={`/${getUser().role}/meeting/${meetingItem._id}`}
      sx={{
        textDecoration: "none",
        "&:hover": {
          backgroundColor: "#f4f4f4",
        },
      }}
    >
      <CardActionArea>
        <Box
          sx={{
            border: "1px solid #f9e6d3",
            padding: "8px",
            borderRadius: "8px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                mt: 1,
                whiteSpace: "nowrap", // Prevent wrapping
                overflow: "hidden", // Hide overflow text
                textOverflow: "ellipsis", // Show ellipsis for overflow
              }}
            >
              {meetingItem.subject}
            </Typography>
            <Chip label={status} color={statusColor} size="small" />
          </Stack>

          <Stack direction="row" spacing={2}>
            <Box sx={{ color: "#777" }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <AlarmOnIcon fontSize="small" />
                <Typography variant="body2">
                  {meetingItem.selectedDate
                    ? new Date(meetingItem.selectedDate).toLocaleDateString()
                    : ""}{" "}
                  | {meetingItem.duration} mins
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="body2">
                  {meetingItem.selectedDate
                    ? new Date(meetingItem.selectedDate).toLocaleTimeString(
                        undefined,
                        { hour: "2-digit", minute: "2-digit", hour12: true }
                      )
                    : ""}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <AlignVerticalBottomIcon fontSize="small" />
                <Typography variant="body2">
                  {meetingItem.meeting_type}
                </Typography>
              </Stack>
            </Box>

            <Box sx={{ alignSelf: "center", pl: 2, color: "#777" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                Participants
              </Typography>
              <AvatarGroup max={4} spacing="small">
                {members.map((item, index) => (
                  <Avatar
                    key={index}
                    alt={item.firstName}
                    src={item.profile_image}
                  />
                ))}
              </AvatarGroup>
            </Box>
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default MeetingCard;
