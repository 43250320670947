import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Avatar } from "@mui/material";

function CountrySelect({
  selectedCountry,
  selectedCountryCode,
  onChange,
  required,
}) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await fetch(
        "https://apis.expoplanner.in/visitor/get-countries"
      );
      const data = await response.json();
      const countryData = data.map((country) => ({
        name: country.name.common,
        code: country.cca2,
        flag: country.flags.png, // URL for the flag image
      }));
      setCountries(countryData);

      // If selectedCountry is not provided, set default based on selectedCountryCode
      if (!selectedCountry && selectedCountryCode) {
        const defaultCountry = countryData.find(
          (country) => country.code === selectedCountryCode
        );
        if (defaultCountry && onChange) {
          onChange(defaultCountry);
        }
      }
    };
    fetchCountries();
    // We include onChange in the dependency array to avoid warnings
  }, [selectedCountry, selectedCountryCode, onChange]);

  const handleChange = (event, value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Autocomplete
      size="small"
      options={countries}
      value={selectedCountry || null}
      onChange={handleChange}
      getOptionLabel={(option) => (option ? option.name : "")}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      renderOption={(props, option) => (
        <li {...props}>
          <Avatar
            src={option.flag}
            alt={option.name}
            sx={{ width: 24, height: 24, marginRight: 1 }}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Country"
          variant="outlined"
          required={required}
        />
      )}
    />
  );
}

export default CountrySelect;
