import { Alert, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { getErrorResponse } from "../../../../utils2024/utils";
import MuPb from "../../../../widgets/MuPb";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import MeetingCard from "../../../../widgets/MeetingCard";

const MeetingsList = ({ status = "", type = "" }) => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `user/get-meetings?status=${status}&type=${type}`
  );

  if (isLoading) {
    return <MuPb />;
  }

  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  return (
    <Box maxWidth={800}>
      {data?.length === 0 && (
        <Typography
          variant="body1"
          color="text.secondary" // Use MUI's color system for consistency
          align="center"
          sx={{
            mt: 4, // Increased margin for better spacing
            fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
            fontWeight: 500, // Medium weight for emphasis
          }}
        >
          No meetings available
        </Typography>
      )}
      <Grid container spacing={2} mt={2}>
        {data?.map((item, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <MeetingCard onClick={() => {}} meetingItem={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MeetingsList;
