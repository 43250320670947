import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  LinearProgress,
  Alert,
} from "@mui/material";
import {
  API_ENDPOINT,
  EMAIL_FOOTER,
  EMAIL_HEADER,
} from "../../../constants/constants";
import { useParams } from "react-router-dom";
import { getErrorResponse } from "../../../utils2024/utils";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

const CreateAccount = () => {
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [accountCreated, setAccountCreated] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [error, setError] = useState("");

  const calculatePasswordStrength = (pass) => {
    let strength = 0;
    if (pass.length >= 8) strength += 25;
    if (/[A-Z]/.test(pass)) strength += 25;
    if (/[a-z]/.test(pass)) strength += 25;
    if (/\d/.test(pass) || /[!@#$%^&*]/.test(pass)) strength += 25;
    return strength;
  };

  const handlePasswordChange = (e) => {
    const pass = e.target.value;
    setPassword(pass);
    setPasswordStrength(calculatePasswordStrength(pass));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    if (passwordStrength < 50) {
      setError("Please choose a stronger password.");
      return;
    }
    try {
      setResetting(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}user/setup-member-account?id=${id}`,
        { password }
      );
      toast.success(data.message);
      setAccountCreated(true);
    } catch (error) {
      setError(getErrorResponse(error));
    } finally {
      setResetting(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* Header */}
      <Box
        component="header"
        sx={{
          textAlign: "center",
          bgcolor: "background.paper",
        }}
      >
        <img src={EMAIL_HEADER} alt="Header" style={{ maxWidth: "100%" }} />
      </Box>

      {/* Main Content */}
      {!accountCreated ? (
        <Box
          component="main"
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 4,
            overflowY: "auto",
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="h4" component="h1" gutterBottom align="center">
              Set Your Password
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="New Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress
                    variant="determinate"
                    value={passwordStrength}
                    sx={{ height: 10, borderRadius: 5 }}
                    color={
                      passwordStrength < 50
                        ? "error"
                        : passwordStrength < 75
                        ? "warning"
                        : "success"
                    }
                  />
                  <Typography variant="body2" align="right">
                    Password Strength: {passwordStrength}%
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={resetting}
                    fullWidth
                    type="submit"
                    sx={{ py: 1.5 }}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box m={4}>
          <Typography textAlign={"center"} variant="body1" color={"green"}>
            Your account is created successfully. To login to your account{" "}
            <a href="/login">Click here</a>
          </Typography>
        </Box>
      )}

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          textAlign: "center",
          pt: 2,
          bgcolor: "background.paper",
        }}
      >
        <img src={EMAIL_FOOTER} alt="Footer" style={{ maxWidth: "100%" }} />
      </Box>
    </Box>
  );
};

export default CreateAccount;
